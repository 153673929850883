import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';

@Injectable()
export class VqsSocketService {

  constructor(
    private socket: Socket
  ) { }

  send(topic: string, payload: any) { 
    console.log(topic, payload); 
    this.socket.emit(topic, payload) 
  }
  
  receive(topic: string) { return this.socket.fromEvent(topic).pipe(map(data => { console.log(data); return data })) }
  
  sendMessage(msg: string) { this.socket.emit("message", msg) }
  getMessage() { return this.socket.fromEvent("message").pipe(map((data) => data['msg'])) }
 
}
