import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NZ_I18N, pt_BR } from 'ng-zorro-antd/i18n';
import { environment } from '../environments/environment';
import { MomentModule } from 'ngx-moment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill'
import 'moment/locale/pt-br';

import { NgxMaskModule, IConfig } from 'ngx-mask';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
const config: SocketIoConfig = { url: environment.apiUrl , options: { transports: ['websocket'] } };

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// services
import { VqsAppService } from './shared/services/app-service';
import { VqsAuthService } from './shared/services/auth/auth.service';
import { VqsPwaService } from './shared/services/pwa-service';
import { VqsSocketService } from './shared/services/socket.service';
import { VimeoUploadService } from './shared/services/vimeo-upload.service';

import { BasicAuthInterceptor } from './shared/services/auth/basic.interceptor';
import { ErrorInterceptor } from './shared/services/auth/error.interceptor';

// translate
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// localePt
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
registerLocaleData(localePt);

import { VqsS3Module } from 'vqs-s3';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    Ng2SearchPipeModule,
    VqsS3Module,
    QuillModule.forRoot(),
    FormsModule, ReactiveFormsModule,
    MomentModule.forRoot(),
    ScrollToModule.forRoot(),
    TranslateModule.forRoot({ loader: {
      provide: TranslateLoader,
      useFactory: (createTranslateLoader),
      deps: [HttpClient]
    }}),
    NgxMaskModule.forRoot(options),
    DeviceDetectorModule.forRoot(),
    SocketIoModule.forRoot(config),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerImmediately' }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: NZ_I18N, useValue: pt_BR },
    VqsAppService, VqsAuthService, VqsPwaService, VimeoUploadService, VqsSocketService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
