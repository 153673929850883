import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

import { VqsAuthService } from './auth.service';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {

  constructor(
    private vqsAuthService: VqsAuthService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this.vqsAuthService.currentUserValue;
    if (currentUser && currentUser.token) {

      if (request.url.indexOf(environment.apiUrl) > -1) {
        request = request.clone({
          setHeaders: {
            Authorization: `Basic ${currentUser.token}`
          }
        });
      }

    }

    return next.handle(request);
  }
}
