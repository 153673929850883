import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './app-routing.guard';

const routes: Routes = [
  
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },


  // DASHBOARD

  { path: 'home', canActivate: [AuthGuard],
  loadChildren : () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },

  { path: 'home/:repository', canActivate: [AuthGuard],
  loadChildren : () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },

  { path: 'dashboard', canActivate: [AuthGuard],
  loadChildren : () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },

  { path: 'dashboard/:repository', canActivate: [AuthGuard],
  loadChildren : () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },


  // PLAYER

  { path: 'player/:id',
  loadChildren : () => import('./player/player.module').then(m => m.PlayerModule) },

  { path: 'player/:id/:company_id/:date_time/:key',
  loadChildren : () => import('./player/player.module').then(m => m.PlayerModule) },
 
  // METTING

  { path: 'meeting/:id', 
  loadChildren : () => import('./meeting/meeting.module').then(m => m.MeetingModule) },

  // S3
  { path: 's3/:id', 
  loadChildren : () => import('./s3/s3.module').then(m => m.S3Module) },
  
  // AUTH

  { path: 'signin',
  loadChildren : () => import('./auth/signin/signin.module').then(m => m.SigninModule) },

  { path: 'signup',
  loadChildren : () => import('./auth/signup/signup.module').then(m => m.SignupModule) },

  { path: 'reset-password',
  loadChildren : () => import('./auth/reset-password/reset-password.module').then(m => m.ResetPasswordModule) },

  { path: 'reset-password/:token',
  loadChildren : () => import('./auth/reset-password/reset-password.module').then(m => m.ResetPasswordModule) },

  { path: '**', redirectTo: 'dashboard' }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    useHash: true,
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload',
    relativeLinkResolution: 'legacy'
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
