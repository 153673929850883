import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';

import { VqsAppService } from './shared/services/app-service';
import { VqsAuthService } from './shared/services/auth/auth.service';
import { VqsPwaService } from './shared/services/pwa-service';
// import { VqsSocketService } from './shared/services/socket.service';

declare var ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  public currentRouter: string;
  public firstLoadConnected: boolean;
  private navigationSubscription;
  private vqsPwaNotificationSubscription;
  private language: string;
  isDark: boolean = false;
  theme: any;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.vqsAppService.setScreenWidth(window.innerWidth);
    this.vqsAppService.setScreenHeight(window.innerHeight);
  }

  constructor(
    private translate: TranslateService,
    public vqsAppService: VqsAppService,
    public vqsAuthService: VqsAuthService,
    public vqsPwaService: VqsPwaService,
    // public vqsSocketService: VqsSocketService,
    private router: Router,
    public sanitizer: DomSanitizer
  ) {
    this.language = localStorage.getItem('language') ? localStorage.getItem('language') : 'pt-br';
    this.translate.setDefaultLang(this.language);
    this.translate.use(this.language);

    this.vqsAppService.setScreenWidth(window.innerWidth);
    this.vqsAppService.setScreenHeight(window.innerHeight);

    this.firstLoadConnected = false;

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.currentRouter = e.url;

        this.vqsAppService.changeCurrentRouter(this.currentRouter);

        try {
          if (typeof ga === 'function') {
            if (event instanceof NavigationEnd) {
              ga('set', 'page', e.urlAfterRedirects);
              ga('send', 'pageview');
            }
          }
        } catch (e) {
          console.log(e);
        }


        if ((this.vqsAuthService.currentUserValue) &&
            (this.vqsAuthService.currentUserValue.id)) {

              // Passes only the first time when connected
              if (!this.firstLoadConnected) {
                this.firstLoadConnected = true;

                // RECEIVE DATA FROM SOCKET.IO
                /*
                this.vqsSocketService
                  .receive(this.vqsAuthService.currentUserValue.id)
                  .subscribe(data => {
                    console.log(data);
                    if (data['refence'] === '/notifications/sumary') {
                      const totalNotifications = (this.currentRouter !== '/notifications') ? data['total'] : 0;
                      // this.vqsAppService.changeTotalNotifications(totalNotifications);
                    }
                });
                */
              }
        }
      }
    });

    this.vqsPwaNotificationSubscription = this.vqsPwaService.swPush.notificationClicks.subscribe( payload => {
      console.log(payload);
      window.open(payload.notification.data.url, '_blank');
    });
  }

  ngOnInit() {
    this.vqsAppService.isDarkChanges.subscribe(isDark => { 
      this.isDark = isDark;

      /*
      if (this.isDark) {
        this.theme = this.sanitizer.bypassSecurityTrustResourceUrl('assets/themes/style.dark.css');
      } else {
        this.theme = this.sanitizer.bypassSecurityTrustResourceUrl('assets/themes/style.default.css');
      }
      */
    });
  }

  ngOnDestroy(): void {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }

    if (this.vqsPwaNotificationSubscription) {
      this.vqsPwaNotificationSubscription.unsubscribe();
    }
  }

}
