import { Injectable } from '@angular/core';
import { SwUpdate, SwPush } from '@angular/service-worker';
import { environment } from '../../../environments/environment';

import { VqsAppService } from './app-service';

@Injectable()
export class VqsPwaService {

  public promptEvent: any;

  constructor(
    private vqsAppService: VqsAppService,
    private readonly swUpdate: SwUpdate,
    public readonly swPush: SwPush
  ) {
    this.swUpdate.available.subscribe(event => {
      // if (confirm('Nova versão disponível, atualizar?')) {
        window.location.reload();
      // }
    });

    if (this.swUpdate.isEnabled) {
      this.swUpdate.checkForUpdate();
    }

    window.addEventListener('appinstalled', event => {
      console.log('appinstalled', event);
    });

    window.addEventListener('beforeinstallprompt', event => {
      event.preventDefault();
      this.promptEvent = event;
    });

    window.addEventListener('push', (event) => {
      console.log('push', event);
    });

    window.addEventListener('notificationclick', (event) => {
      console.log('notificationclick', event);
    });

    window.addEventListener('notificationclose', (event) => {
      console.log('notificationclose', event);
    });

    if (navigator && navigator.serviceWorker) {
      navigator.serviceWorker.ready.then((reg) => {
        // console.log('serviceWorker.ready', reg);
        // reg.sync.register('myTag');
      });
    }

    window.addEventListener('sync', (event) => {
      console.log('sync', event);
      // if (event.tag === 'myTag') {
      //   event.waitUntil(doAsyncOperationForMyTag());
      // }
    });

  }

  callInstallPrompt() {
    if (this.promptEvent !== undefined) {
      this.promptEvent.prompt();

      this.promptEvent.userChoice.then((result) => {
        if (result.outcome === 'accepted') {
          console.log('accepted');
        } else {
          console.log('!accepted');
        }
      });
    }
  }

  subscribeToNotifications() {
    if (this.swPush.isEnabled) {
      this.swPush.requestSubscription({
        serverPublicKey: `${environment.pushPublicKey}`
    })
    .then(sub => {
      const device = (this.vqsAppService.device.device && (this.vqsAppService.device.device !== 'Unknown')) ?
      (this.vqsAppService.device.device + ' ' + this.vqsAppService.device.os + ' ' + this.vqsAppService.device.browser) :
      (this.vqsAppService.device.os + ' ' + this.vqsAppService.device.browser);

      const body = {
        device,
        sub: JSON.stringify(sub)
      };

      this.vqsAppService.apiPost('/push/subscription', body)
      .subscribe((data: {}) => { console.log(data); }, (err: {}) => { console.log(err); });
    })
    .catch(err => console.error(err));
    }
  }

}
