import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { VqsPwaService } from '../pwa-service';

import { environment } from '../../../../environments/environment';
import { User } from '../../interfaces/user';

@Injectable({ providedIn: 'root' })
export class VqsAuthService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(
    private vqsPwaService: VqsPwaService,
    private http: HttpClient,
    private router: Router
  ) {
    const currentUser = sessionStorage.getItem('currentUser') || localStorage.getItem('currentUser');
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(currentUser));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }


  signin(login: string, password: string, persistence: boolean, lastlav: string) {
    return this.http.post<any>(`${environment.apiUrl}/signin`, { login: login.trim().toLowerCase(), password, persistence, lastlav })
      .pipe(map(user => {
        if (persistence) {
          localStorage.setItem('currentUser', JSON.stringify(user));
        } else {
          sessionStorage.setItem('currentUser', JSON.stringify(user));
        }
        this.currentUserSubject.next(user);
        this.vqsPwaService.subscribeToNotifications();
        return user;
      }));
  }

  checkAccount(email: string, phone: string) {
    return this.http.post<any>(`${environment.apiUrl}/checkAccount`, { email, phone })
      .pipe(map(data => {
        return data;
      }));
  }

  sendCode(id: string, name: string, email: string, phone: string) {
    return this.http.post<any>(`${environment.apiUrl}/sendCode`, { id, name, email, phone })
      .pipe(map(data => {
        return data;
      }));
  }

  checkCode(id: string, code: string) {
    return this.http.post<any>(`${environment.apiUrl}/checkCode`, { id, code })
      .pipe(map(data => {
        return data;
      }));
  }

  signup(body: any) {
    return this.http.post<any>(`${environment.apiUrl}/signup`, body)
      .pipe(map(user => {
        sessionStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        this.vqsPwaService.subscribeToNotifications();
        return user;
      }));
  }


  resetPassword(login: string) {
    return this.http.post<any>(`${environment.apiUrl}/resetPassword`, { login })
      .pipe(map(data => {
        return data;
      }));
  }

  changePassword(body: any) {
    return this.http.post<any>(`${environment.apiUrl}/changePassword`, body)
      .pipe(map(data => {
        return data;
      }));
  }


  signout() {
    localStorage.removeItem('currentUser');
    sessionStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.router.navigate(['/signin']);
  }


  getUser(id: string) {
    return this.http.get<any>(`${environment.apiUrl}/users/${id}`)
      .pipe(map(user => {
        return user;
      }));
  }

  updateUser(body: any) {
    return this.http.put<any>(`${environment.apiUrl}/users`, body)
      .pipe(map(data => {
        const newValues = this.currentUserValue;
        if (data.user.name) { newValues.name = data.user.name; }
        if (data.user.email) { newValues.email = data.user.email; }
        if (data.user.doc) { newValues.doc = data.user.doc; }
        if (data.user.specialties) { newValues.specialties = data.user.specialties; }
        if ((data.user.avatar) && (data.user.avatar.url)) { newValues.avatar = data.user.avatar.url; }
        if ((data.user.signature) && (data.user.signature.url)) { newValues.signature = data.user.signature.url; }
        if (sessionStorage.getItem('currentUser')) { sessionStorage.setItem('currentUser', JSON.stringify(newValues)); }
        if (localStorage.getItem('currentUser')) { localStorage.setItem('currentUser', JSON.stringify(newValues)); }
        this.currentUserSubject.next(newValues);
        return data;
      }));
  }

  setCurrentAvatar(avatar) {
    const newUser = this.currentUserValue;
    newUser.avatar = avatar;
    if (sessionStorage.getItem('currentUser')) { sessionStorage.setItem('currentUser', JSON.stringify(newUser)); }
    if (localStorage.getItem('currentUser')) { localStorage.setItem('currentUser', JSON.stringify(newUser)); }
    this.currentUserSubject.next(newUser);
  }

}
