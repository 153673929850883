import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

import 'moment/locale/pt-br';
import * as moment_ from 'moment';
const moment = moment_;
moment.locale('pt-br');

@Injectable({ providedIn: 'root' })
export class VqsAppService {

  isDark: boolean = false

  private currentRouterBehaviorSubject = new BehaviorSubject<string>('/');
  private totalNotificationsBehaviorSubject = new BehaviorSubject<number>(0);

  public currentScreenWidth: number;
  public currentScreenHeight: number;
  public testPWAinBrowser: boolean;

  constructor(
    private deviceService: DeviceDetectorService,
    private http: HttpClient
  ) {
    this.testPWAinBrowser = true;
    const wn = (window.navigator as any);
    if (matchMedia('(display-mode: standalone)').matches) {
        this.testPWAinBrowser = false;
    } else if ('standalone' in wn) {
        this.testPWAinBrowser = !wn.standalone;
    }
  }

  public get device(): any { return this.deviceService.getDeviceInfo(); }
  public get isMobile(): any { return this.deviceService.isMobile();  }
  public get isTablet(): any { return this.deviceService.isTablet();  }
  public get isDesktop(): any { return this.deviceService.isDesktop();  }
  public get isSizeWidth(): any { return this.currentScreenWidth;  }
  public get isSizeHeight(): any { return this.currentScreenHeight;  }
  public get isSizeSmall(): any { return (this.currentScreenWidth <= 768) ? true : false;  }
  public get isSizeMedium(): any { return ((this.currentScreenWidth > 768) && (this.currentScreenWidth <= 1024)) ? true : false;   }
  public get isSizeLarge(): any { return (this.currentScreenWidth > 1024) ? true : false;   }
  public get isPWAinBrowser(): any { return this.testPWAinBrowser;  }
  public get timestamp(): any { return moment().unix();  }

  public get plans(): any { return [
    { name: 'Plano Gratuíto', size: 1, price: 0 },
    { name: 'Plano Básico', size: 10, price: 19.90 },
    { name: 'Plano PRO', size: 50, price: 49.90 },
    { name: 'Plano Cortesia', size: 10, price: 0 },
  ];}

  public get breakPoint(): string {
    return (this.currentScreenWidth < 576) ? 'xs' :
    ((this.currentScreenWidth >= 576) && (this.currentScreenWidth < 768)) ? 'sm' :
    ((this.currentScreenWidth >= 768) && (this.currentScreenWidth < 992)) ? 'md' :
    ((this.currentScreenWidth >= 992) && (this.currentScreenWidth < 1200)) ? 'lg' :
    ((this.currentScreenWidth >= 1200) && (this.currentScreenWidth < 1600)) ? 'xl' : 'xxl';
  }

  setScreenWidth(csw: number) { this.currentScreenWidth = csw; }
  setScreenHeight(csh: number) { this.currentScreenHeight = csh; }

  changeCurrentRouter(valor: string) { this.currentRouterBehaviorSubject.next(valor); }
  getCurrentRouter() { return this.currentRouterBehaviorSubject; }

  private isDarkActived = new BehaviorSubject<boolean>(this.isDark);
  isDarkChanges: Observable<boolean> = this.isDarkActived.asObservable();

  toogleDark(isDark:boolean) {
    localStorage.setItem('isDark', isDark ? '1' : '0');
    this.isDarkActived.next(isDark);
  }

  ucFirstAllWords(str) {
    if (str) {
      const pieces = str.toLowerCase().split(' ');
      for (let i = 0; i < pieces.length; i++) {
          const j = pieces[i].charAt(0).toUpperCase();
          pieces[i] = j + pieces[i].substr(1);
      }
      return pieces.join(' ');
    } else { return str; }
  }

  getObjByKey(obj, key, value) {
    if((!obj) || (!value)) { return null }
    return obj.find(v => v[key] === value);
  }


  getZipCode(zipcode): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json; charset=utf-8');
    return this.http.get<any>('https://viacep.com.br/ws/' + zipcode + '/json/', { headers }).pipe(
      tap((result) => {}),
      catchError(this.handleError<any>('viacep'))
    );
  }

  getLogo(photo) {
    if (photo) {
      const photo2 = photo.replace('http://localhost', 'https://app.laudoaudiovisual.com.br/api/'); 
      return photo2;
    }
  }

  getAvatar(photo) {
    if (photo) {
      return photo.replace('http://localhost', 'https://app.laudoaudiovisual.com.br/api');
    } 
  }
  
  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
    const byteCharacters = atob(b64Data);
    let byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        let slice = byteCharacters.slice(offset, offset + sliceSize);
        let byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  updateBase64(ImageURL) {
    let block = ImageURL.split(';');
    let contentType = block[0].split(':')[1];
    let realData = block[1].split(',')[1];
    let blob = this.b64toBlob(realData, contentType, 512);
    const formData: FormData = new FormData();
    formData.append('file', blob, 'file.png');
    const path = 'files';
    return this.http.post<any>(`${environment.apiUrl}/${path}`, formData)
    .pipe(map(result => {
      return result;
    }));
  }

  updateFile(fileToUpload: File, optimize: string) {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    const path = optimize ? 'files/' + optimize : 'files';
    return this.http.post<any>(`${environment.apiUrl}/${path}`, formData)
    .pipe(map(result => {
      return result;
    }));
  }

  private handleError<T>(operation = '', result?: T) {
    return (error: any): Observable<T> => {
      return of(error as T);
    };
  }

  apiGet(path): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json; charset=utf-8');
    return this.http.get<any>(environment.apiUrl + path, { headers }).pipe(
      tap((result) => {}),
      catchError(this.handleError<any>(path))
    );
  }

  apiGetWtt(path): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json; charset=utf-8');
    return this.http.get<any>(path, { headers }).pipe(
      tap((result) => {}),
      catchError(this.handleError<any>(path))
    );
  }

  apiPostWtt(path, body): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<any>(path, JSON.stringify(body), { headers }).pipe(
      tap((result) => {}),
      catchError(this.handleError<any>(path))
    );
  }

  apiPost(path, body): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<any>(environment.apiUrl + path, JSON.stringify(body), { headers }).pipe(
      tap((result) => {}),
      catchError(this.handleError<any>(path))
    );
  }

  apiPut(path, body): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json; charset=utf-8');
    return this.http.put<any>(environment.apiUrl + path, JSON.stringify(body), { headers }).pipe(
      tap((result) => {}),
      catchError(this.handleError<any>(path))
    );
  }

  apiPutVimeo(code, body): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json; charset=utf-8');
    headers = headers.append('Authorization', 'bearer fa02ee67f2a31d3ab20321e8645d367b');
    headers = headers.append('Accept', 'application/vnd.vimeo.*+json;version=3.4');
    return this.http.put<any>('https://api.vimeo.com/videos/' + code + '/privacy/domains/laudoaudiovisual.com.br', JSON.stringify(body), { headers }).pipe(
      tap((result) => {}),
      catchError(this.handleError<any>(code))
    );
  }


  apiDelete(path): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json; charset=utf-8');
    return this.http.delete<any>(environment.apiUrl + path, { headers }).pipe(
      tap((result) => {}),
      catchError(this.handleError<any>(path))
    );
  }

}
